<div class="row mb-2" *ngIf="empresa.meucardapioPay">
  <div class="col">
    <!-- Status Ativo -->
    <div class="card meucardapio-pay-ativo shadow-sm" *ngIf="empresa.meucardapioPay.ativacaoStatus && empresa.meucardapioPay.lojaAtivou">
      <div class="card-body tuna-ativado">
        <!-- Cabeçalho com Status -->
        <div class="row cadastro-dados">
          <div class="col-md-6">
            <div class="d-flex align-items-center mb-1">
              <div class="status-icon mr-3">
                <i class="fas fa-check-circle text-success fa-2x"></i>
              </div>
              <div class="flex-grow-1">
                <h4 class="mb-0 text-success">Meu Cardápio Pay</h4>
                <div class="d-flex align-items-center">
                  <small class="text-muted mr-3">loja id: <strong>{{empresa.meucardapioPay.idLoja}}</strong></small>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="d-flex align-items-center">
              <div class="btn-group mt-2">
                <a href="https://console.tuna.uy/meu-cardapio/meu-cardapio/{{empresa.meucardapioPay.idLoja}}"
                   target="_blank"
                   class="btn btn-sm btn-outline-primary">
                  <i class="fas fa-external-link-alt mr-1"></i>
                  Acessar Painel Tuna Pay
                </a>
                <a href="/download/Tuna-Manual-Cliente.pdf"
                   target="_blank"
                   class="btn btn-sm btn-outline-secondary">
                  <i class="fas fa-book mr-1"></i>
                  Manual
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="row status-ativacao mt-4">
          <div class="col-md-6">
            <!-- Status da Conta -->
            <div [hidden]="empresa.meucardapioPay.ativacaoStatus === '1'">
              <div class="info-box">
                <div class="info-label">
                  <i class="fas fa-info-circle mr-1"></i>
                  Status da Conta
                </div>
                <div class="d-flex align-items-center" *ngIf="processando()">
                    <span class="badge badge-info">
                      <i class="k-icon k-i-reload mr-1"></i>
                      Processando...
                    </span>

                </div>

                <div class="d-flex align-items-center" *ngIf="estaEmAnalise()">
                    <span class="badge badge-info">
                      <i class="fas fa-clock mr-1"></i>
                      Em análise
                    </span>
                    <button class="btn btn-light btn-sm ml-2"
                            title="Verificar status"
                            kendoTooltip
                            [hidden]="empresa.meucardapioPay.sincronizando"
                            (click)="sincronizeStatusRegistroMCIPay()">
                      <i class="fas fa-sync-alt"></i>
                    </button>
                    <div class="spinner-border spinner-border-sm text-primary ml-2"
                         *ngIf="empresa.meucardapioPay.sincronizando"
                         role="status">
                      <span class="sr-only">Carregando...</span>
                    </div>
                  </div>

                <div class="alert alert-danger mb-0" *ngIf="naoAprovado()">
                  <i class="fas fa-exclamation-triangle mr-2"></i>
                  <strong>Cadastro não aprovado</strong>
                  <p class="mb-0 mt-2">Entre em contato com nossa equipe de suporte pelo WhatsApp para mais informações.</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <!-- Data de Ativação -->
            <div [hidden]="!empresa.meucardapioPay.dataAtivacao || true">
              <div class="info-box">
                <div class="info-label">
                  <i class="far fa-calendar-check mr-1"></i>
                  Data de Ativação
                </div>
                <div class="d-flex align-items-center">
                  <i class="far fa-calendar-alt text-primary mr-2"></i>
                  <strong>{{empresa.meucardapioPay.dataAtivacao | date: 'dd/MM/yyyy HH:mm:ss'}}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Taxas Atuais -->
        <div class="row mt-4" [hidden]="true">
          <div class="col-md-6">
            <div class="payment-method-box">
              <div class="d-flex align-items-center">
                <img src="https://user-images.githubusercontent.com/33992396/99478353-00e4d600-2933-11eb-8228-4bafe8571507.png"
                     alt="PIX"
                     class="payment-icon"/>
                <div class="ml-3">
                  <h6 class="mb-1">PIX</h6>
                  <div class="d-flex align-items-center">
                    <span class="text-muted mr-2">Taxa:</span>
                    <span class="badge badge-success">
                      {{empresa.meucardapioPay.contratoMeucardapioPay.taxaPix * 100}}%
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="payment-method-box">
              <div class="d-flex align-items-center">
                <i class="fas fa-credit-card fa-2x text-primary payment-icon"></i>
                <div class="ml-3">
                  <h6 class="mb-1">Cartão</h6>
                  <div class="d-flex align-items-center">
                    <span class="text-muted mr-2">Taxa:</span>
                    <span class="badge badge-success">
                      {{empresa.meucardapioPay.contratoMeucardapioPay.taxaCartao * 100}}%
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Seção de Ativação -->
    <div class="card ativar-pagamento-meucadapio shadow-sm"
         *ngIf="!empresa.meucardapioPay.ativacaoStatus || !empresa.meucardapioPay?.lojaAtivou">
      <div class="card-body">
        <div class="d-flex align-items-center mb-3">
          <i class="fas fa-star text-warning fa-2x mr-2"></i>
          <h4 class="mb-0">Ative o Meu Cardápio Pay!</h4>
        </div>

        <div class="alert alert-light border">
          <div class="row">
            <div class="col-md-6">
              <div class="d-flex align-items-center mb-3">
                <img src="https://user-images.githubusercontent.com/33992396/99478353-00e4d600-2933-11eb-8228-4bafe8571507.png"
                     style="width: 32px;" class="mr-2"/>
                <div>
                  <h5 class="mb-0">PIX</h5>
                  <small class="text-muted">Taxa por transação:</small>
                  <span class="badge badge-success ml-2">{{empresa.meucardapioPay.contratoMeucardapioPay.taxaPix * 100}}%</span>
                </div>
              </div>


              <div class="d-flex align-items-center mb-3"  [hidden]="true">
                <i class="fas fa-credit-card fa-2x mr-2 text-primary"></i>
                <div>
                  <h5 class="mb-0">Cartão</h5>
                  <small class="text-muted">Taxa por transação:</small>
                  <span class="badge badge-success ml-2">{{empresa.meucardapioPay.contratoMeucardapioPay.taxaCartao * 100}}%</span>
                </div>
              </div>

            </div>

            <div class="col-md-6">

              <div class="text-center mt-4">
                <button class="btn btn-lg btn-primary" (click)="abrirModalRegistrarEmpresaTuna()">
                  <i class="fe-plus-circle mr-2"></i>
                  Ativar Meu Cardápio Pay
                </button>
                <div class="mt-2 text-muted">
                  <small><i class="fas fa-shield-alt mr-1"></i> Ativação rápida e segura</small>
                </div>
              </div>


            </div>
          </div>
        </div>



        <div class="row mt-4">
          <div class="col-md-4">
            <div class="text-center">
              <i class="fas fa-bolt text-warning fa-2x mb-2"></i>
              <h6>Ativação Imediata</h6>
              <small class="text-muted">Comece a receber em minutos</small>
            </div>
          </div>
          <div class="col-md-4">
            <div class="text-center">
              <i class="fas fa-hand-holding-usd text-success fa-2x mb-2"></i>
              <h6>Taxas Competitivas</h6>
              <small class="text-muted">Melhores taxas do mercado</small>
            </div>
          </div>
          <div class="col-md-4">
            <div class="text-center">
              <i class="fas fa-headset text-primary fa-2x mb-2"></i>
              <h6>Suporte Dedicado</h6>
              <small class="text-muted">Atendimento especializado</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="formasDePagamentoMciOnline.length">
  <app-grid-formas-de-pagamento  [empresa]="empresa" [online]="true"
                                 [formasDePagamento]="formasDePagamentoMciOnline" [loading]="loading">

  </app-grid-formas-de-pagamento>
</div>


