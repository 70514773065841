<!-- Cabeçalho e navegação -->
<div class="page-header d-flex justify-content-between align-items-center mb-4">
  <div>
    <h4 class="page-title mb-1">
      <i class="fas fa-paint-roller mr-2"></i>Criar Tema Personalizado
    </h4>
    <ol class="breadcrumb m-0 p-0 bg-transparent">
    <li class="breadcrumb-item"><a routerLink="/admin/temas">Temas</a></li>
    <li class="breadcrumb-item active">Criar Tema Personalizado</li>
  </ol>
</div>
  <button class="btn btn-outline-primary btn-rounded" (click)="voltar()">
    <i class="fas fa-chevron-left mr-1"></i> Voltar
  </button>
</div>

<form [formGroup]="temaForm" id="temaForm" (ngSubmit)="salvarTema()">
  <div class="row">
    <!-- Coluna de configurações -->
    <div class="col-12 col-md-12 col-lg-7 mb-4">
      <!-- Card de informações básicas -->
      <div class="card mb-4 shadow-sm">
        <div class="card-header bg-light">
          <h5 class="mb-0"><i class="fas fa-info-circle mr-2"></i>Informações Básicas</h5>
        </div>
    <div class="card-body">
      <div class="form-group">
            <label for="nome">Nome do Tema</label>
            <input type="text" class="form-control" id="nome" formControlName="nome" required
                  placeholder="Digite um nome para seu tema personalizado">
          </div>
        </div>
      </div>

      <!-- Card de Geral -->
      <div class="card mb-4 shadow-sm">
        <div class="card-header bg-light">
          <h5 class="mb-0"><i class="fas fa-palette mr-2"></i>Geral</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="corFundoDoSite">Cor de Fundo do Site</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" name="corFundoDoSite" formControlName="corFundoDoSite"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor principal do fundo do site</small>
            </div>
            <div class="col-md-6 mb-3">
              <label for="corFundoElementos">Cor de Fundo de Elementos</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" name="corFundoElementos" formControlName="corFundoElementos"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor para cards e elementos</small>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="corBorda">Cor de Bordas</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corBorda"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor para bordas e separadores</small>
            </div>
          </div>
        </div>
      </div>

      <!-- Card de Topo e Menu -->
      <div class="card mb-4 shadow-sm">
        <div class="card-header bg-light">
          <h5 class="mb-0"><i class="fas fa-window-maximize mr-2"></i>Topo e Menu</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="corFundo">Cor de Fundo do Topo</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corFundo"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor do cabeçalho e menu</small>
            </div>
            <div class="col-md-6 mb-3">
              <label for="corTextoTopo">Cor do Texto do Topo</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corTextoTopo"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor do texto no topo e menu</small>
            </div>
          </div>
        </div>
      </div>

      <!-- Card de Conteúdo -->
      <div class="card mb-4 shadow-sm">
        <div class="card-header bg-light">
          <h5 class="mb-0"><i class="fas fa-align-left mr-2"></i>Conteúdo</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="corTextoPrimaria">Cor do Texto Principal</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corTextoPrimaria"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor dos títulos e textos principais</small>
            </div>
            <div class="col-md-6 mb-3">
              <label for="corTextoSecundaria">Cor do Texto Secundário</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corTextoSecundaria"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor para descrições e textos secundários</small>
            </div>
          </div>
        </div>
      </div>

      <!-- Card de Produtos e Preços -->
      <div class="card mb-4 shadow-sm">
        <div class="card-header bg-light">
          <h5 class="mb-0"><i class="fas fa-shopping-cart mr-2"></i>Produtos e Preços</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="corPreco">Cor dos Preços</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corPreco"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor para preços dos produtos</small>
            </div>
            <div class="col-md-6 mb-3">
              <label for="corPrecoAdicional">Cor das Tags de Preço</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corPrecoAdicional"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor para preços de adicionais</small>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="corDestaque">Cor de Destaque</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corDestaque"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor para elementos de destaque</small>
            </div>
          </div>
        </div>
      </div>

      <!-- Card de Elementos Interativos -->
      <div class="card mb-4 shadow-sm">
        <div class="card-header bg-light">
          <h5 class="mb-0"><i class="fas fa-mouse-pointer mr-2"></i>Elementos Interativos</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="corBotao">Cor dos Botões</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corBotao"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor de fundo dos botões</small>
            </div>
            <div class="col-md-6 mb-3">
              <label for="corTextoBotao">Cor do Texto dos Botões</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corTextoBotao"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor do texto dentro dos botões</small>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="corHover">Cor de Hover</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corHover"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor ao passar o mouse sobre elementos</small>
            </div>
          </div>
        </div>
      </div>

      <!-- Card de Rodapé -->
      <div class="card mb-4 shadow-sm">
        <div class="card-header bg-light">
          <h5 class="mb-0"><i class="fas fa-shoe-prints mr-2"></i>Rodapé</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="corFundoRodape">Cor de Fundo do Rodapé</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corFundoRodape"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor de fundo do rodapé</small>
            </div>
            <div class="col-md-6 mb-3">
              <label for="corTextoRodape">Cor do Texto do Rodapé</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corTextoRodape"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor do texto do rodapé</small>
            </div>
            <div class="col-md-6 mb-3">
              <label for="corBordaRodape">Cor da Borda do Rodapé</label>
              <div class="mt-2">
                <kendo-colorpicker class="color-picker-compact" formControlName="corBordaRodape"></kendo-colorpicker>
              </div>
              <small class="form-text text-muted mt-2">Cor da borda do rodapé</small>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Coluna de visualização -->
    <div class="col-12 col-md-12 col-lg-5">
      <div class="card shadow-sm sticky-preview">
        <div class="card-header bg-light">
          <h5 class="mb-0"><i class="fas fa-eye mr-2"></i>Visualização em Tempo Real</h5>
        </div>

        <!-- Abas de navegação para diferentes visualizações -->
        <div class="preview-tabs">
          <div class="nav nav-tabs" role="tablist">
            <button class="nav-link active" id="home-tab" data-toggle="tab" data-target="#preview-home" type="button" role="tab" aria-controls="preview-home" aria-selected="true">
              <i class="fas fa-home"></i> Início
            </button>
          </div>
        </div>

        <div class="tab-content">
          <!-- Visualização da tela inicial -->
          <div class="tab-pane fade show active" id="preview-home" role="tabpanel" aria-labelledby="home-tab">
            <div class="card-body p-2">
              <div class="d-flex justify-content-center">
                <div class="device-wrapper">
                  <div class="device-frame" style="border-radius: 36px; border: 12px solid #222; overflow: hidden;">
                    <iframe id="mobile-content-home" [src]="urlSegura" width="375px" height="667px"
                            style="border: none;display: block;" frameborder="0" allowfullscreen></iframe>
                  </div>
                  <div class="device-stripe"></div>
                  <div class="device-header"></div>
                  <div class="device-sensors"></div>
                  <div class="device-btns"></div>
                  <div class="device-power"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Alternativa: Visualização de elementos específicos -->
        <div class="card-body border-top">
          <h6 class="mb-3"><i class="fas fa-puzzle-piece mr-2"></i>Elementos Específicos</h6>
          <div class="preview-elements">
            <div class="row">
              <div class="col-6">
                <div class="preview-element-card">
                  <div class="preview-element-title">Botão</div>
                  <div class="preview-element-content">
                    <button class="btn preview-button" [style.background-color]="temaForm.get('corBotao')?.value" [style.color]="temaForm.get('corTextoBotao')?.value">Comprar</button>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="preview-element-card">
                  <div class="preview-element-title">Preço</div>
                  <div class="preview-element-content">
                    <span class="preview-price" [style.color]="temaForm.get('corPreco')?.value">R$ 29,90</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-6">
                <div class="preview-element-card">
                  <div class="preview-element-title">Adicional Individual</div>
                  <div class="preview-element-content">
                    <div class="preview-adicional">
                      <span>Queijo extra</span>
                      <span class="badge badge-success" [style.background-color]="temaForm.get('corPrecoAdicional')?.value" [style.color]="temaForm.get('corTextoBotao')?.value">+ R$ 5,00</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="preview-element-card">
                  <div class="preview-element-title">Destaque</div>
                  <div class="preview-element-content">
                    <span class="preview-destaque" [style.color]="temaForm.get('corDestaque')?.value">Promoção!</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<!-- Footer Fixo -->
<div class="card-footer">
  <div class="d-flex">
    <button type="submit" form="temaForm" class="btn btn-primary" [disabled]="!temaForm.valid || salvando">
      <i class="fas fa-save mr-2"></i>Salvar Tema
    </button>
    <button type="button" class="btn btn-secondary" (click)="cancelar()">
      <i class="fas fa-times mr-2"></i>Cancelar
    </button>
  </div>
</div>

<!-- Mensagens de feedback -->
<div class="toast-container position-fixed bottom-0 end-0 p-3" style="z-index: 1050">
  <div *ngIf="mensagemSucesso" class="toast show bg-success text-white" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header bg-success text-white">
      <i class="fas fa-check-circle mr-2"></i>
      <strong class="me-auto">Sucesso</strong>
      <button type="button" class="btn-close" (click)="mensagemSucesso = null"></button>
    </div>
    <div class="toast-body">
      {{ mensagemSucesso }}
    </div>
  </div>

  <div *ngIf="mensagemErro" class="toast show bg-danger text-white" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header bg-danger text-white">
      <i class="fas fa-exclamation-circle mr-2"></i>
      <strong class="me-auto">Erro</strong>
      <button type="button" class="btn-close" (click)="mensagemErro = null"></button>
    </div>
    <div class="toast-body">
      {{ mensagemErro }}
    </div>
  </div>
</div>
