import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TemaPersonalizadoService } from '../../services/tema-personalizado.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsService } from '../../fidelidade/ConstantsService';
import { AutorizacaoService } from '../../services/autorizacao.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-tela-criar-tema-personalizado',
  templateUrl: './tela-criar-tema-personalizado.component.html',
  styleUrls: ['./tela-criar-tema-personalizado.component.scss']
})
export class TelaCriarTemaPersonalizadoComponent implements OnInit, AfterViewInit {
  temaForm: FormGroup;
  salvando = false;
  mensagemSucesso: string;
  mensagemErro: string;
  idTema = '';

  corDoSite = '#FFFFFF';
  constructor(
    private fb: FormBuilder,
    private temaService: TemaPersonalizadoService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private constantsService: ConstantsService,
    private autorizacaoService: AutorizacaoService,
    private sanitizer: DomSanitizer
  ) { }

  corFundoDoSite = '#FFFFFF';
  dominioEmpresa: string;
  urlSegura: SafeResourceUrl;

  ngOnInit(): void {
    this.temaForm = this.fb.group({
      id: [''],
      nome: ['', Validators.required],
      corFundoDoSite: ['#FFFFFF', Validators.required],
      corFundoElementos: ['#F8F9FA', Validators.required],
      corFundo: ['#FFFFFF', Validators.required],
      corTextoFundo: ['#000000', Validators.required],
      corTextoTopo: ['#000000', Validators.required],
      corTextoPrimaria: ['#000000', Validators.required],
      corTextoSecundaria: ['#6C757D', Validators.required],
      corBotao: ['#4a81d4', Validators.required],
      corTextoBotao: ['#FFFFFF', Validators.required],
      corPrecoAdicional: ['#28a745', Validators.required],
      corDestaque: ['#FF9800', Validators.required],
      corHover: ['#007BFF', Validators.required],
      corBorda: ['#DEE2E6', Validators.required],
      corPreco: ['#28a745', Validators.required],
      corTexto: ['#FFFFFF', Validators.required],
      corTextoRodape: ['#FFFFFF', Validators.required],
      corFundoRodape: ['#FFFFFF', Validators.required],
      corBordaRodape: ['#DEE2E6', Validators.required]
    });

    this.activatedRoute.params.subscribe(params => {
      this.idTema = params['id'];
      if (this.idTema) {
        this.carregarTema(this.idTema);
      }
    });

    // Observar mudanças nas cores para atualizar as visualizações
    this.observarMudancasCores();

    // Verificar se o usuário está autenticado
    this.autorizacaoService.carregueUsuario().subscribe(usuario => {
      if (usuario) {
        this.constantsService.empresa$.subscribe(empresa => {
          if (empresa) {
            this.dominioEmpresa = `https://${empresa.dominio}.meucardapio.ai`;
            this.urlSegura = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.dominioEmpresa}/loja?mobile=true`);
          }
        });
      }

    });
  }

  observarMudancasCores(): void {
    // Lista de propriedades de cores para observar
    const propriedadesCores = [
      'corFundoDoSite', 'corFundoElementos', 'corFundo', 'corTextoFundo',
      'corTextoPrimaria', 'corTextoSecundaria', 'corBotao', 'corTextoBotao',
      'corPrecoAdicional', 'corDestaque', 'corHover', 'corBorda', 'corPreco',
      'corTextoRodape', 'corFundoRodape', 'corBordaRodape', 'corTextoTopo'
    ];

    // Observar cada propriedade
    propriedadesCores.forEach(prop => {
      this.temaForm.get(prop)?.valueChanges.subscribe(valor => {
        // Processamento de mudanças específicas
        this.processarMudancaDeCor(prop, valor);
      });
    });
  }

  // Método para processar mudanças de cores com tratamento de erros
  processarMudancaDeCor(propriedade: string, valor: string): void {
    setTimeout(() => {
      // Aplicar diretamente em todos os iframes
      const iframeIds = ['mobile-content-home'];

      iframeIds.forEach(id => {
        const iframe = document.getElementById(id) as HTMLIFrameElement;
        if (!iframe) return;

        try {
          // Preparar mensagem com todos os valores atuais do tema
          const mensagem = {
            tipo: 'atualizacao-tema',
            propriedade: propriedade,
            valor: valor,
            todosValores: this.temaForm.value
          };

          // Enviar mensagem ao iframe
          const origemIframe = new URL(iframe.src).origin;
          iframe.contentWindow?.postMessage(mensagem, origemIframe);

        } catch (e) {
          console.log(`Erro ao enviar mensagem para iframe ${id}:`, e);
        }
      });
    }, 100);
  }

  carregarTema(id: string) {
    this.temaService.obtenha(id).then(tema => {
      if (tema) {
        this.temaForm.patchValue(tema);
      }
    }).catch(erro => {
      console.error('Erro ao carregar tema:', erro);
      this.mensagemErro = 'Erro ao carregar tema. Por favor, tente novamente.';
    });
  }

  salvarTema() {
    if (this.temaForm.valid) {
      this.salvando = true;
      this.mensagemSucesso = '';
      this.mensagemErro = '';

      this.temaService.crieTema(this.temaForm.value)
        .then(() => {
          ///this.salvando = false;
          if (this.idTema) {
            this.mensagemSucesso = 'Tema personalizado atualizado com sucesso!';
          } else {
            this.mensagemSucesso = 'Tema personalizado criado com sucesso!';
          }
          setTimeout(() => {
            this.router.navigate(['/admin/temas']);
          }, 2000);
        })
        .catch((erro) => {
          this.salvando = false;
          this.mensagemErro = 'Erro ao criar tema personalizado: ' + erro;
        });
    }
  }

  cancelar() {
    this.router.navigate(['/admin/temas']);
  }

  ngAfterViewInit(): void {
  }

  voltar() {
    this.router.navigateByUrl('/admin/temas');
  }
}
